.App {
  font-family: "Source Code Pro",monospace;
  font-family: "DM Sans", sans-serif;
  font-size: '16px';
}

span.ant-page-header-heading-title, h2.title{
  font-family: 'Press Start 2P';
  color: rgb(58, 208, 91 );
  color: rgb(0,168, 255);
}

.info {
 flex:1;
 font-weight: 500;
 font-size: 16px;
  text-align: center;
  margin-top: 50px;
}

h2 {
  text-align: center;
}

#inputDiv .msgInput, #inputDiv .ant-btn{
  margin-top: 18px;
}

#container {
  width: 1164px; 
  max-width: 90%;
  margin: 0px auto;
}

.topDiv {
  display: flex;
  padding: 0px;
  margin: 64px auto;
  gap: 20px;
}

#inputDiv {
  text-align: right;
  flex: 1 ;
}

.imageDiv {
  flex: 0 0 350px;
}

#preview {
  border: 1px solid rgb(240, 240, 240);
  border-radius: 10px;
}

.messageList {
  margin: auto;
  margin-top: 32px;
  padding-bottom: 32px; 
}

#inputBox > div > span{
  border:none;
}

#inputBox{
  border:2px solid rgb(43, 43, 43);
  border:none;
  background: #d2d2d2;
  padding: 20px;
  border-radius:5px;
}

button.ant-btn, a.ant-btn{
  border:none;
  border: 1px solid rgb(58, 208, 91 );
  color: rgba(0, 0, 0, 0.85);
  color: rgb(58, 208, 91 );
  font-family:"Press Start 2P" ;
  font-size: 12px;
  padding: 2px 8px;
}

a.ant-btn{
  margin-top:50px;
  font-size: 16px;
}

input.ant-input{
  color:black;
}

textarea.ant-input{
  border:none;
  color:white;
  background-image: linear-gradient(to right, rgb(58, 208, 91 ), rgb(0, 168, 255));
}

textarea.ant-input::placeholder, input.ant-input::placeholder{
  color: rgb(223, 223, 223);
}

.content {
  background-image: linear-gradient(to right, rgb(58, 208, 91 ), rgb(0, 168, 255));
  color:white;
  padding:4px 10px;
  border-radius: 5px 5px 5px 0px;
  display: inline-block;
}

.sentBubble {
  border-radius: 5px 5px 0px 5px;
}

span.ant-page-header-heading-title{
  color: rgb(0,168, 255);
}
 

.messageList .ant-list-items .ant-list-item, .messageList .ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child, .ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child {
  border-bottom: none;
}

.ant-list-item button {
  margin-right: 14px;
}

canvas.identicon {
  display: none;
}

.sentMessages .ant-list-vertical .ant-list-item{
  text-align: right;
}

.mintWithNFT {
  margin:15px;
}

.mintWithNFT button {
  margin:3px;
}